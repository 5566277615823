import React from 'react';
import Wrapper from "../components/Wrapper";

function TelegramPage(props) {
    return (
        <Wrapper>

        </Wrapper>
    );
}

export default TelegramPage;
